import React from "react";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import axios from "../../../axios";

class AiTitle extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            titles: [],
            loading: false,
            error: null,
            selectedTitle: "original" // Başlangıçta orijinal başlık seçili
        };
    }
    
    static propTypes = {
        originalTitle: PropTypes.string.isRequired,
        token: PropTypes.string.isRequired,
        updateTitle: PropTypes.func.isRequired
    };
    
    static defaultProps = {
        // Varsayılan değerler buraya eklenebilir
    };

    componentDidMount() {
        // Bileşen yüklendiğinde yapılacak işlemler
        this.setState({
            loading: false,
            error: null
        });
    }

    generateTitle = () => {
        axios.defaults.headers.common["Authorization"] = this.props.token;
        const postData = {
            originalTitle: this.props.originalTitle,
        };
        this.setState({
            loading: true,
            error: null
        });

        axios.post("/video/generate-title-with-ai", postData)
            .then(response => {
                console.log(response.data);
                this.setState({
                    loading: false,
                    titles: response.data.titles || [],
                    error: null,
                });
            })
            .catch(err => {
                const msg = err.response && err.response.data && err.response.data.message 
                    ? err.response.data.message 
                    : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };

    handleTitleClick = (title, index) => {
        this.setState({ selectedTitle: index });
        // Seçilen başlığı parent bileşene gönder
        this.props.updateTitle(title);
    };

    // Orijinal başlığa tıklandığında çağrılacak fonksiyon
    handleOriginalTitleClick = () => {
        this.setState({ selectedTitle: "original" });
        // Orijinal başlığı parent bileşene gönder
        this.props.updateTitle(this.props.originalTitle);
    };

    render() {
        const { titles, loading, error, selectedTitle } = this.state;
        const { originalTitle } = this.props;
        
        return (
            <Paper className="RightSide GeneralPaper t" elevation={1} square>
                <Typography variant="h5">AI Başlık Oluşturucu</Typography>

                {error && (
                    <Typography variant="body1" color="error" style={{marginTop: "15px"}}>
                        {error}
                    </Typography>
                )}

                    <>
                        <div style={{marginTop: "20px"}}>
                        <Typography variant="subtitle1" style={{marginTop: "15px"}}>Orijinal Başlık:</Typography>
                            
                            {/* Orijinal başlık */}
                            <div 
                                onClick={this.handleOriginalTitleClick}
                                style={{
                                    padding: "10px",
                                    margin: "5px 0",
                                    cursor: "pointer",
                                    backgroundColor: selectedTitle === "original" ? "#e3f2fd" : "transparent",
                                    border: selectedTitle === "original" ? "1px solid #2196f3" : "1px solid #e0e0e0",
                                    borderRadius: "4px",
                                    transition: "all 0.2s ease"
                                }}
                            >
                                <Typography variant="body1">
                                    {originalTitle}
                                </Typography>
                            </div>
                            
                            {/* Önerilen başlıklar */}
                            {titles && titles.length > 0 && (
                                <>
                                    <Typography variant="subtitle1" style={{marginTop: "15px"}}>Önerilen Başlıklar:</Typography>
                                    {titles.map((title, index) => (
                                        <div 
                                            key={index}
                                            onClick={() => this.handleTitleClick(title, index)}
                                            style={{
                                                padding: "10px",
                                                margin: "5px 0",
                                                cursor: "pointer",
                                                backgroundColor: selectedTitle === index ? "#e3f2fd" : "transparent",
                                                border: selectedTitle === index ? "1px solid #2196f3" : "1px solid #e0e0e0",
                                                borderRadius: "4px",
                                                transition: "all 0.2s ease"
                                            }}
                                        >
                                            <Typography variant="body1">{title}</Typography>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>


                        {loading && (<div>Yükleniyor...</div>)}

                        <Button style={{display: "block", width: "100%", marginTop: "30px"}} 
                                onClick={this.generateTitle} 
                                variant="contained" 
                                color="primary"
                                disabled={loading}>
                            Başlık Oluştur
                        </Button>
                    </>
            </Paper>
        )
    }
}

export default AiTitle;
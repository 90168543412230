import React from "react";
import SpeakingUrl from "speakingurl";
import Moment from "moment";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import PlayIcon from "@material-ui/icons/PlayArrow";
import Visibility from "@material-ui/icons/Visibility";
import CloudUpload from "@material-ui/icons/CloudUpload";
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";
import SelectCore from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Badge from "@material-ui/core/Badge";
import Snackbar from "@material-ui/core/Snackbar";

import SelectPhoto from "../../../components/SelectPhoto/SelectPhoto";
import axios from "../../../axios";
import Select from "../../../components/Select/Select";
import Loading from "../../../components/Loading/Loading";
import JwPlayer from "../../../components/JwPlayer/JwPlayer";
import * as actions from "../../../store/actions";
import AiTitle from "../AiTitle/AiTitle";


class EditVideo extends React.Component {
    state = {
        imageOpen: false,
        videoOpen: false,
        deleteDialog: false,
        photoSelect: false,
        imageOpenUrl: null,
        loading: false,
        redirect: null,

        video: {
            changed: {
                cover: false,
                categories: false,
                pornstars: false,
                channels: false,
                tags: false,
                status: false
            },
            originalTitle: "",
            id: null,
            title: "",
            slug: "",
            pornstars: [],
            categories: [],
            tags: [],
            channels: [],
            description: "",
            seoTitle: "",
            seoDescription: "",
            date: "",
            status: "",
            statusText: "",
            differentSlug: true,
            site: {},
            duration: "00:00",
            originalSlug: "",
            statistics: {
                views: 0,
                likes: 0,
                dislikes: 0
            },
            full_url: "",
            sourceSite: "",
            cover: "",
            photos: [],
            videoSources: {},
            customPhotos: [],
            willPublishReddit: true,
            redditPublishStatus: 0,
            willPublishTwitter: true,
            twitterPublishStatus: 0,
            willPublishVk: true,
            vkPublishStatus: 0,
            willPublishTelegram: true,
            telegramPublishStatus: 0,
            downloadLog: {}
        },
        seo: {
            titleLimit: 60,
            descriptionLimit: 230
        }
    };

    constructor() {
        super();
        this.photoUploadInput = React.createRef();
    }

    open = (name, value) => {
        if (name === "imageOpen") {
            this.setState({[name]: true, imageOpenUrl: value});
        } else {
            this.setState({[name]: true});
        }

    };

    close = (name) => {
        this.setState({[name]: false});
    };

    deleteVideo = () => {
        axios.defaults.headers.common["Authorization"] = this.props.token;
        const postData = {
            contentID: this.props.match.params.id,
            domainID: this.props.activeDomain.id
        };
        this.setState({
            loading: true
        });

        axios.post("/video/delete", postData)
            .then(response => {
                this.props.refreshVideos();
                this.setState({
                    loading: false,
                    redirect: "/videos"
                });
            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };

    inputChangeHandler = (name, val) => {
        let video = {...this.state.video, [name]: val};

        let changed = {...this.state.video.changed};

        if (typeof changed[name] !== "undefined") {
            video.changed[name] = true;
        }


        this.setState({
            video: video
        });
    };

    checkboxChangeHandler = name => event => {
        let video = {...this.state.video, [name]: event.target.checked};
        this.setState({
            video: video
        });
    };


    seoChangeHandler = (name, val) => {
        let seo = {...this.state.seo};
        let inputName = "";
        if (name === "title") {
            if (seo.titleLimit < val.length) {
                // return false;
            }
            inputName = "seoTitle";
        } else if (name === "description") {
            if (seo.descriptionLimit < val.length) {
                // return false;
            }
            inputName = "seoDescription";
        }

        this.setState({
            seo: seo
        });
        this.inputChangeHandler(inputName, val);

    };

    titleChangeHandler = (name, value) => {
        this.inputChangeHandler("title", value);
        console.log(this.state.video.differentSlug);

        if (!this.state.video.differentSlug) {
            let slug = SpeakingUrl(value, {
                custom: {
                    "ö": "o",
                    "ü": "u"
                }
            });
            if (!slug) {
                slug = this.randomStr(16);
            }

            setTimeout(() => {
                this.inputChangeHandler("slug", slug);
            }, 5);
        }

    };
    randomStr = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    };

    slugChangeHandler = (name, value) => {
        this.inputChangeHandler(name, value);
        setTimeout(() => {
            this.inputChangeHandler("differentSlug", true);
        }, 5);
    };

    selectMapping = (arr) => {
        return arr.map(c => {
            return {
                label: c.name,
                value: c.id
            };
        });
    };


    getVideo = () => {
        axios.defaults.headers.common["Authorization"] = this.props.token;
        const postData = {
            contentID: this.props.match.params.id,
            domainID: this.props.activeDomain.id
        };
        this.setState({
            loading: true
        });

        axios.post("/video/getVideo", postData)
            .then(response => {
                const selectedCategories = this.selectMapping(response.data.categories);
                const selectedPornstars = this.selectMapping(response.data.pornstars);
                const selectedChannels = this.selectMapping(response.data.channels);
                const selectedTags = this.selectMapping(response.data.tags);


                const videoSources = Object.keys(response.data.sources).map(s => {
                    return {
                        label: s,
                        file: response.data.sources[s],
                        type: "video/mp4"
                    };
                });


                this.setState({
                    loading: false,
                    video: {
                        ...this.state.video,
                        id: response.data.id,
                        title: response.data.title,
                        originalTitle: response.data.title,
                        slug: response.data.slug,
                        differentSlug: response.data.status === "draft" || response.data.status === "extension" ? false : true,
                        originalSlug: response.data.slug,
                        categories: selectedCategories,
                        pornstars: selectedPornstars,
                        channels: selectedChannels,
                        tags: selectedTags,
                        description: response.data.description,
                        seoTitle: response.data.seoTitle ? response.data.seoTitle : "",
                        seoDescription: response.data.seoDescription ? response.data.seoDescription : "",
                        duration: response.data.duration,
                        statusText: response.data.statusText,
                        status: response.data.status,
                        statistics: {
                            ...this.state.video.statistics,
                            views: response.data.statistics.views,
                            likes: response.data.statistics.likes,
                            dislikes: response.data.statistics.dislikes
                        },
                        sourceSite: response.data.source,
                        sourceUrl: response.data.sourceUrl,
                        cover: response.data.cover,
                        photos: response.data.photos,
                        videoSources: videoSources,
                        date: response.data.date ? Moment(response.data.date).format("YYYY-MM-DDTHH:mm") : Moment().format("YYYY-MM-DDTHH:mm"),
                        full_url: response.data.full_url,
                        willPublishReddit: response.data.shares.reddit.status === 0 ? false : true,
                        redditPublishStatus: response.data.shares.reddit.status,
                        willPublishTwitter: response.data.shares.twitter.status === 0 ? false : true,
                        twitterPublishStatus: response.data.shares.twitter.status,
                        willPublishVk: response.data.shares.vk.status === 0 ? false : true,
                        vkPublishStatus: response.data.shares.vk.status,
                        willPublishTelegram: response.data.shares.telegram.status === 0 ? false : true,
                        telegramPublishStatus: response.data.shares.telegram.status,
                        downloadLog: response.data.downloadLog
                    }
                });

                if (response.data.status === "extension") {
                    this.titleChangeHandler("title", response.data.title);
                }
            })
            .catch(e => {
                console.log(e);
                if (e.response && e.response.data && e.response.data.code && e.response.data.code === 404) {
                    this.setState({
                        loading: false,
                        error: "Bir hata oluştu",
                        redirect: "/videos"
                    });
                } else {
                    this.setState({
                        loading: false,
                        error: "Bir hata oluştu"
                    });
                }

            });

    };

    componentDidMount() {
        if (this.props.activeDomain.id !== null) {
            this.getVideo();
        }
        window.addEventListener("paste", e => {
            if (e.clipboardData && e.clipboardData.files && e.clipboardData.files.length > 0) {
                this.uploadInputChangeHandler(e.clipboardData);
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeDomain.id !== null && this.props.activeDomain.id !== prevProps.activeDomain.id) {
            this.getVideo();
        }
    }

    uploadInputChangeHandler = e => {

        let file;
        if (e.target && e.target.files && e.target.files.length > 0) {
            file = e.target.files[0];
        } else if (e.files && e.files.length > 0) {
            file = e.files[0];
        }

        const formData = new FormData();
        formData.append("photo", file);
        this.setState({
            loading: true
        });

        axios.post("/video/upload-photo", formData)
            .then(response => {
                let video = {...this.state.video};
                video.photos.push(response.data.url);
                video.customPhotos.push(response.data.url);
                this.setState({
                    video: video,
                    loading: false
                });
            });

    };

    errorCloseHandler = () => {
        this.setState({
            error: null
        });
    };

    updateVideoRequest = () => {
        this.setState({
            loading: true
        });

        const requiredInfos = [
            this.state.video.title,
            this.state.video.slug,
            this.state.video.cover,
            this.state.video.categories
        ];

        let completeRequireInfo = true;

        requiredInfos.forEach(i => {
            let complete = false;
            switch (typeof i) {
                case "string":
                    complete = i !== "";
                    break;
                case "object":
                    complete = i.length > 0;
                    break;
                case "array":
                    complete = i.length > 0;
                    break;
                case "number":
                    complete = i > 0;
                    break;
                default:
                    complete = false;
                    break;
            }

            if (completeRequireInfo !== false) {
                completeRequireInfo = complete;
            }
        });

        if (completeRequireInfo !== true) {
            this.setState({
                error: "Tüm zorunlu alanları doldurun.",
                loading: false
            });
        }

        const postData = this.state.video;
        postData.domainID = this.props.activeDomain.id;
        if (postData.status === "extension") postData.status = "draft";

        axios.post("video/update", postData)
            .then(response => {
                this.props.refreshVideos();
                this.setState({
                    loading: false,
                    redirect: "/videos"
                });

            })
            .catch(err => {
                const msg = err.response.data.message ? err.response.data.message : "Bir hata oluştu.";
                this.setState({
                    error: msg,
                    loading: false
                });
            });
    };


    render() {
        const redirect = this.state.redirect ? <Redirect to={{
            pathname: this.state.redirect,
            state: {
                // updateVideos: true,
            }
        }}/> : null;

        const photos = this.state.video.photos.map(photo => {
            return <SelectPhoto key={photo} img={photo} checked={photo === this.state.video.cover} checkClick={() => {
                this.inputChangeHandler("cover", photo);
            }} showClick={() => {
                this.open("imageOpen", photo);
            }}/>;
        });

        // const categories = this.props.categories.map(category => {
        //     return {
        //         label: category.title,
        //         value: category.id,
        //     }
        // })

        // const pornstars = this.props.pornstars.map(pornstar => {
        //     return {
        //         label: pornstar.title,
        //         value: pornstar.id,
        //     }
        // });
        const channels = this.props.channels.map(channel => {
            return {
                label: channel.title,
                value: channel.id
            };
        });
        const tags = this.props.tags.map(tag => {
            return {
                label: tag.title,
                value: tag.id
            };
        });

        const loading = this.state.loading ? <Loading size={100}/> : null;
        let error = null;
        if (this.state.error) {
            error = (
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={this.state.error !== null}
                    autoHideDuration={5000}
                    onClose={this.errorCloseHandler}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        "className": "snackbar danger"
                    }}

                    message={<span id="message-id">{this.state.error}</span>}
                />
            );
        }

        let redditMediaSharings;

        if (this.props.settings.isRedditActive) {
            switch (this.state.video.redditPublishStatus) {
                case 0:
                    redditMediaSharings = (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.video.willPublishReddit}
                                    onChange={this.checkboxChangeHandler("willPublishReddit")}
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Reddit'te Paylaşılsın"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                case 1:
                    redditMediaSharings = (
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Reddit'te Paylaşıldı"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                case 2:
                    redditMediaSharings = (
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Reddit'te Paylaşılacak (Kuyrukta)"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                default:
                    redditMediaSharings = null;
                    break;
            }
        } else {
            redditMediaSharings = (
                <FormControlLabel
                    disabled
                    control={
                        <Checkbox
                            value="1"
                            color="primary"
                        />
                    }
                    label="Reddit Hesabı Aktif Değil"
                    style={{
                        width: "100%"
                    }}
                />
            );
        }


        let twitterMediaSharings;
        if (this.props.settings.isTwitterActive) {
            switch (this.state.video.twitterPublishStatus) {
                case 0:
                    twitterMediaSharings = (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.video.willPublishTwitter}
                                    onChange={this.checkboxChangeHandler("willPublishTwitter")}
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Twitter'da Paylaşılsın"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                case 1:
                    twitterMediaSharings = (
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Twitter'da Paylaşıldı"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                case 2:
                    twitterMediaSharings = (
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Twitter'da Paylaşılacak (Kuyrukta)"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                default:
                    twitterMediaSharings = null;
                    break;
            }
        } else {
            twitterMediaSharings = (
                <FormControlLabel
                    disabled
                    control={
                        <Checkbox
                            value="1"
                            color="primary"
                        />
                    }
                    label="Twitter Hesabı Aktif Değil"
                    style={{
                        width: "100%"
                    }}
                />
            );
        }


        let vkMediaSharings;
        if (this.props.settings.isVkActive) {
            switch (this.state.video.vkPublishStatus) {
                case 0:
                    vkMediaSharings = (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.video.willPublishVk}
                                    onChange={this.checkboxChangeHandler("willPublishVk")}
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="VK'da Paylaşılsın"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                case 1:
                    vkMediaSharings = (
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="VK'da Paylaşıldı"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                case 2:
                    vkMediaSharings = (
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="VK'da Paylaşılacak (Kuyrukta)"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                default:
                    vkMediaSharings = null;
                    break;
            }
        } else {

            vkMediaSharings = (
                <FormControlLabel
                    disabled
                    control={
                        <Checkbox
                            value="1"
                            color="primary"
                        />
                    }
                    label="VK Hesabı Aktif Değil"
                    style={{
                        width: "100%"
                    }}
                />
            );
        }
        let telegramMediaSharings;

        if (this.props.settings.isTelegramActive) {
            switch (this.state.video.telegramPublishStatus) {
                case 0:
                    telegramMediaSharings = (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.video.willPublishTelegram}
                                    onChange={this.checkboxChangeHandler("willPublishTelegram")}
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Telegram'da Paylaşılsın"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                case 1:
                    telegramMediaSharings = (
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Telegram'da Paylaşıldı"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                case 2:
                    telegramMediaSharings = (
                        <FormControlLabel
                            disabled
                            control={
                                <Checkbox
                                    checked
                                    value="1"
                                    color="primary"
                                />
                            }
                            label="Telegram'da Paylaşılacak (Kuyrukta)"
                            style={{
                                width: "100%"
                            }}
                        />
                    );
                    break;
                default:
                    telegramMediaSharings = null;
                    break;
            }
        } else {
            telegramMediaSharings = (
                <FormControlLabel
                    disabled
                    control={
                        <Checkbox
                            value="1"
                            color="primary"
                        />
                    }
                    label="Telegram Hesabı Aktif Değil"
                    style={{
                        width: "100%"
                    }}
                />
            );
        }

        const socialMediaSharings = (
            <Paper className="RightSide  GeneralPaper" elevation={1} square>
                <Typography variant="h5">Sosyal Medya Paylaşımı</Typography>
                {redditMediaSharings}
                {twitterMediaSharings}
                {vkMediaSharings}
                {telegramMediaSharings}
            </Paper>
        );


        let downloadStatus = "Bilinmiyor";
        if ((this.state.video.downloadLog && this.state.video.downloadLog.download_status) || (this.state.video.downloadLog && this.state.video.downloadLog.download_status === 0)) {
            switch (this.state.video.downloadLog.download_status) {
                case 0:
                    downloadStatus = "İndirme Bekleniyor";
                    break;
                case 1:
                    downloadStatus = "İndiriliyor";
                    break;
                case 2:
                    downloadStatus = "İndirildi";
                    break;
                case 4:
                    downloadStatus = "İndirme Hatası";
                    break;
                default:
                    downloadStatus = "Bilinmiyor";
                    break;
            }
        }


        return (
            <React.Fragment>
                {redirect}
                {loading}
                {error}
                <div className="AddVideo">


                    <div className="VideoDetails">
                        <Dialog
                            open={this.state.imageOpen}
                            onClose={() => {
                                this.close("imageOpen");
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="lg"
                        >
                            <DialogContent>
                                <img src={this.state.imageOpenUrl} style={{maxWidth: "100%"}} alt=""/>
                            </DialogContent>

                        </Dialog>
                        <Dialog
                            open={this.state.videoOpen}
                            onClose={() => this.close("videoOpen")}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="lg"
                            fullWidth>
                            <DialogContent>
                                <JwPlayer
                                    id="ufuk"
                                    image={this.state.video.cover}
                                    sources={this.state.video.videoSources}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.deleteDialog}
                            onClose={() => {
                                this.close("deleteDialog");
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth>
                            <DialogContent>
                                <p>Video kalıcı olarak silinecektir. </p>
                                <p><strong>Onaylıyor musunuz?</strong></p>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    this.close("deleteDialog");
                                }} color="primary">
                                    İptal
                                </Button>
                                <Button onClick={this.deleteVideo} color="secondary" variant="contained">
                                    SİL
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.photoSelect}
                            onClose={() => {
                                this.close("photoSelect");
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth={false}>
                            <DialogContent>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} sm={9} xl={10}>
                                        <Grid container spacing={24}>
                                            {photos}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3} xl={2} className="PhotoUploadButton">
                                        <input id="photoUploadInput" accept="image/*"
                                               onChange={this.uploadInputChangeHandler} type="file"
                                               ref={this.photoUploadInput}
                                               style={{position: "absolute", top: "-9999px"}}/>
                                        <label htmlFor="photoUploadInput">
                                            <Button variant="contained" component="span" color="default">
                                                FARKLI FOTOĞRAF YÜKLE&nbsp;&nbsp;
                                                <CloudUpload/>
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={3} xl={3}>
                                <Paper className="LeftSide" elevation={1} square>
                                    <div className="Cover">
                                        <img src={this.state.video.cover} alt={this.state.video.title}/>
                                        <div className="Buttons">
                                            <PlayIcon onClick={() => this.open("videoOpen")}/>
                                            <Visibility onClick={() => this.open("imageOpen", this.state.video.cover)}/>
                                            <Badge onClick={() => {
                                                this.open("photoSelect");
                                            }} className="PhotoLibraryBadge"
                                                   badgeContent={this.state.video.photos.length} color="primary"
                                                   style={{top: "-1px"}}>
                                                <PhotoLibrary/>
                                            </Badge>
                                        </div>
                                        <div className="DarkLayer"></div>
                                    </div>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="th">Durum:</TableCell>
                                                <TableCell>{this.state.video.statusText}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="th">Süre:</TableCell>
                                                <TableCell>{this.state.video.duration}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="th">İzlenme:</TableCell>
                                                <TableCell>{this.state.video.statistics.views}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="th">Like:</TableCell>
                                                <TableCell>{this.state.video.statistics.likes}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="th">Dislike:</TableCell>
                                                <TableCell>{this.state.video.statistics.dislikes}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="th">Kaynak Site:</TableCell>
                                                <TableCell>
                                                    <a target="_blank" rel="noopener noreferrer"
                                                       href={this.state.video.sourceUrl}>{this.state.video.sourceSite}</a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="th">Video İndirme Durumu:</TableCell>
                                                <TableCell>
                                                    {downloadStatus}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>

                                </Paper>

                                <Button style={{display: "block", width: "100%", marginTop: "30px"}} onClick={() => {
                                    this.open("deleteDialog");
                                }} variant="contained" color="secondary">
                                    VİDEOYU SİL
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} xl={6}>
                                <Paper className="GeneralPaper" elevation={1} square>
                                    <TextField
                                        fullWidth
                                        label="Başlık"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={(e) => this.titleChangeHandler("title", e.target.value)}
                                        value={this.state.video.title}
                                    />
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        label="Link Yapısı"
                                        value={this.state.video.slug}
                                        onChange={(e) => this.slugChangeHandler("slug", e.target.value)}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Paper>
                                <Paper className="GeneralPaper" elevation={1} square>
                                    <Select
                                        changed={(val) => this.inputChangeHandler("pornstars", val)}
                                        placeholder="Yıldızlar"
                                        // options={pornstars}
                                        value={this.state.video.pornstars}
                                        label="Yıldızlar"
                                        ajaxUrl="pornstar/searchPornstar"
                                        domainID={this.props.activeDomain.id}
                                        multi
                                        className="AddVideoSelect"
                                    />
                                    <Select
                                        changed={(val) => this.inputChangeHandler("categories", val)}
                                        placeholder="Kategoriler"
                                        // options={categories}
                                        value={this.state.video.categories}
                                        label="Kategoriler"
                                        ajaxUrl="category/searchCategory"
                                        domainID={this.props.activeDomain.id}
                                        multi
                                        className="AddVideoSelect"
                                    />
                                    <Select
                                        changed={(val) => this.inputChangeHandler("tags", val)}
                                        placeholder="Etiketler"
                                        options={tags}
                                        value={this.state.video.tags}
                                        label="Etiketler"
                                        multi
                                        creatable
                                        ajaxUrl="tag/searchTag"
                                        domainID={this.props.activeDomain.id}
                                        className="AddVideoSelect"
                                    />
                                    <Select
                                        changed={(val) => this.inputChangeHandler("channels", val)}
                                        placeholder="Kanallar"
                                        options={channels}
                                        value={this.state.video.channels}
                                        label="Kanallar"
                                        ajaxUrl="channel/searchChannel"
                                        domainID={this.props.activeDomain.id}
                                        multi
                                        className="AddVideoSelect"
                                    />
                                </Paper>
                                <Paper className="GeneralPaper" elevation={1} square>
                                    <TextField
                                        fullWidth
                                        label="Açıklama"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        multiline
                                        rows={5}
                                        value={this.state.video.description}
                                        onChange={(e) => this.inputChangeHandler("description", e.target.value)}
                                        rowsMax={10}
                                    />
                                </Paper>
                                <Paper className="GeneralPaper" elevation={1} square>
                                    <TextField
                                        fullWidth
                                        label="Seo Başlığı"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={this.state.video.seoTitle}
                                        onChange={(e) => this.seoChangeHandler("title", e.target.value)}
                                        helperText={this.state.video.seoTitle.length + " / " + this.state.seo.titleLimit}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Seo Açıklaması"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        multiline
                                        rows={3}
                                        value={this.state.video.seoDescription}
                                        margin="normal"
                                        onChange={(e) => this.seoChangeHandler("description", e.target.value)}
                                        helperText={this.state.video.seoDescription.length + " / " + this.state.seo.descriptionLimit}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={3} xl={3}>
                                <a href={this.state.video.full_url} target="_blank" rel="noopener noreferrer">
                                    <Button style={{display: "block", width: "100%", marginBottom: "30px"}}
                                            variant="contained" color="secondary">
                                        VİDEOYA GİT
                                    </Button>
                                </a>

                                <AiTitle updateTitle={(title) => this.titleChangeHandler("title", title)} token={this.props.token} originalTitle={this.state.video.originalTitle} />

                                {socialMediaSharings}

                                <Paper className="RightSide  GeneralPaper" elevation={1} square>
                                    <TextField
                                        label="Yayınlanma Tarihi"
                                        type="datetime-local"
                                        fullWidth
                                        value={this.state.video.date}
                                        onChange={(e) => this.inputChangeHandler("date", e.target.value)}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />

                                    <FormControl margin="normal" fullWidth>
                                        <InputLabel htmlFor="status">Durum</InputLabel>
                                        <SelectCore
                                            value={this.state.video.status === "extension" ? "draft" : this.state.video.status}
                                            onChange={(e) => this.inputChangeHandler("status", e.target.value)}
                                            inputProps={{
                                                id: "status"
                                            }}
                                        >
                                            <MenuItem value="draft">Taslak</MenuItem>
                                            <MenuItem value="published">Yayında</MenuItem>
                                            <MenuItem value="scheduled">Zamanlanmış</MenuItem>
                                        </SelectCore>
                                    </FormControl>


                                    <Button onClick={this.updateVideoRequest}
                                            style={{display: "block", width: "100%", marginTop: "30px"}}
                                            variant="contained" color="primary">
                                        GÜNCELLE
                                    </Button>

                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={12} xl={12}>
                                <Grid container spacing={24}>
                                    {photos}
                                </Grid>
                            </Grid>

                        </Grid>

                    </div>

                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        categories: state.category.categories,
        pornstars: state.pornstar.pornstars,
        channels: state.channel.channels,
        tags: state.tag.tags,
        activeDomain: state.domain.activeDomain,
        token: state.auth.token,
        settings: state.setting.settings
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateVideos: () => dispatch(actions.getVideos()),
        refreshVideos: () => dispatch(actions.refreshVideos())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVideo);